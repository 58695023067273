<template>
  <DefaultLayout>
    <FloatingBox class="sm:w-128">
      <div class="flex items-center flex-col sm:flex-row">
        <exclamation-triangle :size="56" class="rounded-full p-1 w-14 flex-shrink-0 text-white bg-red-500  mr-6 mb-6" />
        <div>
          <h1 class="text-lg font-bold mb-4">{{$t('error.404.title')}}</h1>
          <p>{{$t('error.404.description')}}</p>
        </div>
      </div>

    </FloatingBox>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '../layouts/Default.vue'
import FloatingBox from '../components/FloatingBox'
import ExclamationTriangle from '../icons/ExclamationTriangle.vue';

export default {
  name: 'Search',
  components: {
    DefaultLayout,
    FloatingBox,
    ExclamationTriangle,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  metaInfo() {
    return {
      title: this.$t('error.404.title'),
    }
  } 
};
</script>
